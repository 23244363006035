function sendMessage(e) {
    var post_url = "forms/submit.php";
    var request_method = "POST";
    var form_data = $("#contact-form").serialize();
    const input = document.querySelector("#phone");
    data = window.intlTelInputGlobals.getInstance(input).selectedCountryData;
    form_data = `${form_data}&country=${data.iso2}&code=${data.dialCode}`;

    $("input, button, select", "#contact-form").prop(
        "disabled",
        true
    );

    $("#submit-errors").text("");
    $("#submit-errors").removeClass("visible");

    $.ajax({
        url: post_url,
        type: request_method,
        data: form_data,
        statusCode: {
            400: function (responseObject, textStatus, jqXHR) {

                $("input, button, select", "#contact-form").prop(
                    "disabled",
                    false
                );

                $("#submit-errors").text("Subscribing failed. Please try again!");
                $("#submit-errors").addClass("visible");
            },

        },
    }).done(function (response) {


        $("#submit-errors").text("Subscription sucsessful. Thank you!");
        $("#submit-errors").addClass("visible");
    });

    return false;
}